import React, { useEffect, useRef, useState } from "react";
import classNames from "./custom.module.scss";
import "./custom.scss";

//assets
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import editIcon from "../../assets/images/icon/edit.svg";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const CustomFileUpload = ({
  title,
  placeholderImage,
  type,
  stateValue,
  setState,
  name,
  func,
}) => {
  // Create a ref for the input element
  const fileInputRef = useRef(null);

  // Handle file selection
  const handleFileChange = (e) => {
    if (func) {
      func(e);
    }
    const files = Array.from(e.target.files);

    // Update state based on type (single or multiple)
    setState((prevState) => ({
      ...prevState,
      [name]:
        type === "multiple" ? [...(prevState[name] || []), ...files] : files[0],
    }));

    // Clear the input value to allow selecting the same file again if needed
    e.target.value = "";
  };

  // Trigger file input click when the input container is clicked
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={classNames.customFileUpload}>
      {title && <div className={classNames.title}>{title}</div>}

      <div className={classNames.inputContainer}>
        {type === "multiple" ? (
          <>
            {/* Show uploaded files */}
            {stateValue[name]?.length > 0 &&
              stateValue[name].map((file, index) => (
                <div key={index} className={classNames.eachInput}>
                  <span>{file.name}</span>
                </div>
              ))}

            {/* Upload button */}
            <div className={classNames.eachInput} onClick={handleClick}>
              <img src={placeholderImage} alt="Placeholder" />
            </div>

            {/* Hidden file input */}
            <input
              ref={fileInputRef}
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </>
        ) : (
          // Single file upload
          <div className={classNames.eachInput} onClick={handleClick}>
            {stateValue[name] ? (
              <span>{stateValue[name].name}</span>
            ) : (
              <img src={placeholderImage} alt="Placeholder" />
            )}
            <input type="file" ref={fileInputRef} onChange={handleFileChange} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomFileUpload;

export const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  typee,
  placeholder,
  indexValue,
  objName,
  mapName,
  option,
  currentValue,
  indexx,
  asteriskIconn,
  typeee,
  noopen,
  selectedEmployee,
  allEmployees,
  refreshPage,
  apiVal,
  setLocalRefresh,
  mapNameType,
  isAlreadyThere,
  isTitleVisible,
  //accounts stream repo specific
  setStateFunc,
  setStateValueArray,
  setStateValueObj,
  stateValueDirect,
  toolTip,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [otherSubCards, setOtherSubCards] = useState({});
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  //function

  const toggleSelectionMultiple = (field, value) => {
    setState((prevState) => {
      const currentFieldValues = prevState[field] || [];
      const updatedValues = currentFieldValues.includes(value)
        ? currentFieldValues.filter((item) => item !== value) // Remove the value
        : [...currentFieldValues, value]; // Add the value

      setIsOpen(false);
      return {
        ...prevState,
        [field]: updatedValues,
      };
    });
  };

  //renderings

  useEffect(() => {
    if (isAlreadyThere) {
      setSelectedOption(stateValue[name]);
    }
  }, [isAlreadyThere]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        height: typee === "singleMultiple" ? "unset" : "",
      }}
    >
      {isTitleVisible && <div className={classNames.title}>{title}</div>}
      <div
        className={`${classNames.inputContainer} ${
          isTitleVisible && classNames.withTitle
        }`}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown?.length < 1 ? "none" : "",
          zIndex: isOpen ? "3" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span>
            {stateValueDirect
              ? stateValueDirect
              : name && stateValue[name]
              ? stateValue[name]
              : stateValue
              ? stateValue
              : selectedOption
              ? selectedOption
              : title
              ? title
              : placeholder
              ? placeholder
              : "Select an option"}
          </span>
          <span
            style={{
              visibility: typeee === "removeArrow" ? "hidden" : "",
              color: "#5F6163",
              display: "flex",
              alignItems: "center",
            }}
          >
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </span>
        {isOpen && (
          <ul
            className="dropdown-list"
            style={{ height: typee === "addPolicyToAll" ? "50vh" : "" }}
          >
            <li
              style={{
                display:
                  dropdown?.length <= 4 || placeholder == "Relation"
                    ? "none"
                    : "",
              }}
            >
              <input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            </li>
            {typee === "singleMultiple" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();

                          if (name) {
                            toggleSelectionMultiple(name, eachitem);
                          } else {
                            setState(eachitem);
                          }
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : typee === "single" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);

                          if (setStateFunc) {
                            setStateFunc(eachitem, ...setStateValueArray);
                          } else if (name) {
                            setState({
                              ...stateValue,
                              [name]: eachitem,
                            });
                            // setState((prev) => {
                            //   return { ...prev, [name]: eachitem };
                            // });
                          } else {
                            setState(eachitem);
                          }
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : typee === "objVal" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            mapName?.length > 0 && Array.isArray(mapName)
                              ? mapName[0]
                              : mapName
                              ? eachitem[mapName]
                              : eachitem[objName]
                          );
                          if (setStateFunc) {
                            setStateFunc(
                              [
                                eachitem[mapName],
                                ...setStateValueObj?.documentID,
                              ],
                              [
                                eachitem[objName],
                                ...setStateValueObj?.receiptID,
                              ]
                            );
                          } else if (
                            mapName?.length > 0 &&
                            Array.isArray(mapName)
                          ) {
                            setState((prev) => {
                              return { ...prev, [name]: eachitem };
                            });
                          } else {
                            setState({
                              ...stateValue,
                              [name]: eachitem[objName],
                            });
                          }
                        }}
                        key={eachitem[objName] + index}
                        style={{
                          flexDirection:
                            mapNameType === "flex"
                              ? ""
                              : mapName?.length > 0 && Array.isArray(mapName)
                              ? "column"
                              : "",
                          alignItems:
                            mapName?.length > 0 && Array.isArray(mapName)
                              ? "flex-start"
                              : "",
                          gap: mapNameType === "flex" ? "4px" : "",
                          marginBottom: mapNameType === "flex" ? "0" : "",
                        }}
                      >
                        {/* <img src={eachitem} alt={eachitem} /> */}
                        {mapName?.length > 0 && Array.isArray(mapName)
                          ? mapName?.map((eachItem) => {
                              return (
                                <div key={eachitem[eachItem] + index}>
                                  {eachitem[eachItem]}
                                </div>
                              );
                            })
                          : mapName
                          ? eachitem[mapName]
                          : eachitem[objName]}
                      </li>
                    );
                  })
              : typee === "obj" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState({
                            ...stateValue,
                            [name]: eachitem,
                          });
                        }}
                        key={eachitem + index}
                      >
                        <img src={eachitem} alt={eachitem} />
                        {eachitem}
                      </li>
                    );
                  })
              : dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem?.app_name);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.app_code,
                          });
                        }}
                        key={eachitem?.app_name + index}
                      >
                        <img
                          src={eachitem?.app_icon}
                          alt={eachitem?.app_name}
                        />
                        {eachitem?.app_name}
                      </li>
                    );
                  })}
            {/* {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"allCompanies"}
              >
                All Companies
              </li>
            ) : (
              ""
            )} */}
          </ul>
        )}
      </div>
      {typee === "singleMultiple" && stateValue[name]?.length > 0 && (
        <div className={classNames.allItems}>
          {typee === "singleMultiple" &&
            stateValue[name]?.length > 0 &&
            stateValue[name].map((eachItem, index) => {
              return <div key={eachItem + index}>{eachItem}</div>;
            })}
        </div>
      )}
    </div>
  );
};

export const CustomInput = ({
  title,
  type,
  placeholder,
  stateValue,
  setState,
  name,
  view,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    setViewMode(view);
  }, []);

  return (
    <div className={classNames.customInput}>
      {title && <div className={classNames.title}>{title}</div>}
      {viewMode ? (
        <div className={classNames.viewContainer}>
          <span>
            {name && stateValue[name]
              ? stateValue[name]
              : stateValue
              ? stateValue
              : "-"}
          </span>
          <img
            src={editIcon}
            alt="editIcon"
            onClick={() => setViewMode(false)}
          />
        </div>
      ) : (
        <div className={classNames.inputContainer}>
          <input
            placeholder={placeholder}
            onChange={(event) => {
              let currentInput = event?.target?.value;
              if (name) {
                setState((prev) => {
                  return { ...prev, [name]: currentInput };
                });
              } else {
                setState(currentInput);
              }
            }}
            type={type === "password" && !passwordVisible ? "password" : "text"}
          />
          {type === "password" && (
            <div
              className={classNames.passwordIcon}
              onClick={() => setPasswordVisible((prev) => !prev)}
            >
              {passwordVisible ? <IoEyeOffOutline /> : <IoEyeOutline />}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const SpinnerLoading = () => {
  return <div class="lds-dual-ring"></div>;
};
