import React from "react";
import Images from "../../../assets/0-exporter";
import OtpInput from "react-otp-input";
import { forgetPasswordRequest } from "../../../services/postAPIs";

export default function RSTwo({ setLoading, details, setStep, setDetails }) {
  const [pin, setPin] = React.useState("");
  const handleChange = (e) => {
    setPin(e);
  };
  const handleResend = async () => {
    setLoading(true);
    let res = await forgetPasswordRequest(details.email);
    if (res.data.status) {
      setLoading(false);
      setPin("");
    } else {
      setStep(0);
    }
  };
  const handleEnter = async (e) => {
    if (e.keyCode === 13) {
      setDetails({ ...details, code: pin });
      setStep(2);
    }
  };
  React.useEffect(() => {
    if (pin.length === 6) {
      document.addEventListener("keydown", handleEnter);
    } else {
      document.removeEventListener("keydown", handleEnter);
    }
    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [pin]);
  return (
    <div className="reset-password-two">
      <img src={Images.mainLogo} />
      <div className="r-p-two-otp-wrapper">
        <p>Enter The Code Sent To {details.email}</p>
        <OtpInput
          placeholder="------"
          isInputSecure={false}
          isInputNum={true}
          shouldAutoFocus={true}
          numInputs={true}
          style={{ width: 100 }}
          value={pin}
          onChange={handleChange}
          numInputs={6}
        />
      </div>
      <div onClick={() => handleResend()} className="r-p-two-footer">
        Resend Code
      </div>
    </div>
  );
}
