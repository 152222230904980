import React from "react";
import nextId from "react-id-generator";
import "./payees.style.scss";
import { useHistory, useParams } from "react-router";
import { Context } from "../../../Context/Context";
import {
  fetchSyncTransactionPayees,
  liquidAssetList,
} from "../../../../services/postAPIs";
import LoadingAnimation from "../../../../lotties/LoadingAnimation";
import ChangeCurrency from "../../../Portals/ChangeCurrency/ChangeCurrency";
import Images from "../../../../assets/0-exporter";

function Payees({ split, handlePDFDownload, downloadRef }) {
  const { userid, app, type } = useParams();
  const liquidListRef = React.useRef();
  const [shadow, setShadow] = React.useState(false);
  const context = React.useContext(Context);
  const {
    selectedTab,
    valueFormatter,
    currencyImageList,
    userDetails,
    dateFormatter,
    nameImageList,
    refreshTable,
    updateState,
    miniTabSelected,
  } = context;

  const [transactionList, setTransactionList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [changeCurrency, setChangeCurrency] = React.useState("");
  const [selectedTransaction, setSelectedTransaction] = React.useState({
    num: 0,
    data: null,
  });

  const setUpTransactionList = async () => {
    let res = await fetchSyncTransactionPayees({
      userId: userid,
    });
    console.log(res, "Fetch transaction response");
    if (res.data.status) {
      let temp = res.data.data.map((obj) => {
        return {
          ...obj,
          // displayCurrency: type,
          // displayValue: obj.amount,
        };
      });
      // localStorage.setItem(miniTabSelected.keyId, JSON.stringify(temp));
      setTransactionList([...temp]);
      setLoading(false);
    } else {
      setTransactionList([]);
      setLoading(false);
    }
  };

  const handleCoinClick = (obj, transaction) => {
    transactionList[transaction.num] = {
      ...transactionList[transaction.num],
      displayCurrency: obj.coin,
      displayValue: obj.value * transaction.data.displayValue,
    };
    localStorage.setItem(
      miniTabSelected.keyId,
      JSON.stringify([...transactionList])
    );
    setTransactionList([...transactionList]);
    setChangeCurrency("");
  };
  const handleScroll = () => {
    if (liquidListRef.current.scrollTop > 70) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };
  React.useEffect(() => {
    setLoading(true);
    if (userid) {
      setUpTransactionList();
    }
  }, [userid]);

  React.useEffect(() => {
    if (refreshTable === null) return;
    setLoading(true);
    setUpTransactionList();
  }, [refreshTable]);

  return (
    <div
      ref={downloadRef}
      className={`liquid-asset-main ${split ? "split-affected" : ""}`}
    >
      {!changeCurrency ? (
        ""
      ) : (
        <ChangeCurrency
          critical="amount"
          handleCoinClick={handleCoinClick}
          currencyImageList={currencyImageList}
          valueFormatter={valueFormatter}
          closeIt={() => {
            setChangeCurrency("");
            setSelectedTransaction({ num: 0, data: null });
          }}
          transaction={selectedTransaction}
          currency={changeCurrency}
        />
      )}
      <div className={`liquid-asset-header ${shadow ? "show-shadow" : ""}`}>
        {headerList.slice(0, split ? 4 : 8).map((obj) => (
          <h6 key={obj.keyId}>{obj.name}</h6>
        ))}
      </div>
      <div
        onScroll={handleScroll}
        ref={liquidListRef}
        className="liquid-asset-body"
      >
        {loading || !currencyImageList ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => (
            <div className="liquid-asset-loading">
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
            </div>
          ))
        ) : !transactionList.length ? (
          <div className="empty-list">
            <h4>No Transaction Found for {type}.</h4>
          </div>
        ) : (
          transactionList.map((obj, num) => (
            <div className="single-transaction single-transaction-sync">
              <h6>{obj?.account_name}</h6>
              <h6>{obj?.type}</h6>
              <h6>{obj?.payee_id}</h6>
              <h6>{obj?.payee_type}</h6>
              <h6>{obj?.payee_name}</h6>
              <h6>{obj?.account_class}</h6>
              <h6>{obj?.trx_type}</h6>
              <h6>{obj?.account_id ? obj?.account_id : "-"}</h6>
            </div>
          ))
        )}
      </div>
      <div className="liquid-asset-footer">
        <h6>
          {!transactionList || loading ? 0 : transactionList.length}{" "}
          Transactions
        </h6>
        <h6>
          <button>
            <img src={Images.share} alt="share" />
          </button>
          <button onClick={() => updateState("openDownloadTab", true)}>
            <img src={Images.download} alt="download" />
          </button>
        </h6>
      </div>
    </div>
  );
}

export default Payees;

const headerList = [
  { keyId: nextId(), name: "Account Name", id: "accountName" },
  { keyId: nextId(), name: "Type", id: "type" },
  { keyId: nextId(), name: "Payee ID", id: "payeeID" },
  { keyId: nextId(), name: "Payee Type", id: "payeeType" },
  { keyId: nextId(), name: "Payee Name", id: "payeeName" },
  { keyId: nextId(), name: "Account Class", id: "accountClass" },
  { keyId: nextId(), name: "TRX Type", id: "trxType" },
  { keyId: nextId(), name: "Account ID", id: "accountID" },
];
