import React from "react";
import { useHistory } from "react-router";
import Images from "../../../assets/0-exporter";
import { fetchAllBankers } from "../../../services/getAPIs";
import "./bankers.style.scss";
export default function Bankers() {
  const history = useHistory();
  const [bankerList, setBankerList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");

  const setUpBankerList = async () => {
    let res = await fetchAllBankers();
    if (res.data.status) {
      setBankerList(res.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const handleClick = (obj) => {
    localStorage.setItem("userEmail", obj.email);
    history.push("/select");
  };
  React.useEffect(() => {
    setUpBankerList();
  }, []);
  return (
    <div className="bankers-main">
      <div className="title-logo">
        <img src={Images.mainLogo} />
      </div>
      <div className="bankers-list">
        <div className="bankers-search">
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Bankers"
          />
        </div>
        <div className="banker-list-wrapper">
          {loading
            ? [1, 2, 3, 4, 5, 6, 7, 8].map((obj) => (
                <div className="banker-list-loader">
                  <button /> <p>XXXXXXXXXXXXXXXXXXXXXXXX</p>
                </div>
              ))
            : bankerList
                .filter((x) => {
                  return x.displayName
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase());
                })
                .map((obj) => (
                  <div
                    onClick={() => handleClick(obj)}
                    className="single-banker"
                  >
                    <h6>
                      <img
                        src={
                          !obj.profilePicURL ? Images.banker : obj.profilePicURL
                        }
                      />
                      {obj.displayName}
                    </h6>
                  </div>
                ))}
        </div>
      </div>
    </div>
  );
}
