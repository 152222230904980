import React from "react";
import Images from "../../../assets/0-exporter";
import "./normal-login.style.scss";
export default function NormalLogin({
  setCredential,
  credential,
  admin,
  handleSubmit,
  loading,
  setType,
}) {
  const emailRegex = /([\w\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?/gm;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      className="normal-login"
    >
      <div className="normal-login-title">
        <img src={Images.mainLogo} alt="accounts" />
      </div>
      <div className="normal-login-input">
        <p>Email</p>
        <input
          type="email"
          placeholder="Enter Email..."
          value={credential.email}
          name="email"
          onChange={(e) =>
            setCredential({ ...credential, [e.target.name]: e.target.value })
          }
        />
      </div>
      <div className="normal-login-input">
        <p>Password</p>
        <input
          type="password"
          placeholder="Enter Password..."
          value={credential.password}
          name="password"
          onChange={(e) =>
            setCredential({ ...credential, [e.target.name]: e.target.value })
          }
        />
      </div>
      <div className="normal-login-last">
        <button
          disabled={!emailRegex.test(credential.email) || !credential.password}
        >
          {loading ? "Logging In....." : "Login"}
        </button>
        <p onClick={() => setType("reset")} className={admin ? "d-none" : ""}>
          Forget Password?
        </p>
      </div>
    </form>
  );
}
