import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import classNames from "./withauth.module.scss";
import Images from "../../../assets/0-exporter";
import CustomFileUpload, { SpinnerLoading } from "../../Custom";
import cloudUploadIcon from "../../../assets/images/icon/cloudupload.svg";
import uploadPlusIcon from "../../../assets/images/icon/uploadplus.svg";
import axios from "axios";
import ManualLoading from "../../../loadings/ManualLoading";
import toast from "react-hot-toast";
import { TiTick } from "react-icons/ti";

function WithAuth() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [synced, setSynced] = useState(false);
  const [currentStep, setCurrentStep] = useState("step 1"); // default -> step 1
  const [userID, setUserID] = useState("");
  const [allFiles, setAllFiles] = useState({});
  const [progressPercentage, setProgressPercentage] = useState(0);

  //loading bar

  // React.useEffect(() => {
  //   localStorage.clear();
  // }, []);

  async function executeQuincyFileUpload(userID, allFiles) {
    setProgressPercentage(20);
    setLoading(true);
    try {
      // Create FormData instance
      const formData = new FormData();

      // Append the userId field
      formData.append("userId", userID);

      // Append the csv_transactions (single file)
      if (allFiles.spreadsheet) {
        formData.append("csv_transactions", allFiles.spreadsheet);
      }

      // Append images_payment_advise (multiple files)
      if (allFiles.paymentAdvises && allFiles.paymentAdvises.length > 0) {
        allFiles.paymentAdvises.forEach((file) => {
          formData.append("images_payment_advise", file);
        });
      }
      setTimeout(() => {
        setProgressPercentage(55);
      }, 1000);
      setTimeout(() => {
        setProgressPercentage(80);
      }, 2500);
      // Make the POST request
      let response = await axios.post(
        "https://finacebackend.marketsverse.com/api/quickbooks/process-transactions",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (
        response?.data?.status &&
        response?.data?.data?.transactions.length > 0
      ) {
        setProgressPercentage(100);
        localStorage.setItem("processTransactions", JSON.stringify(response));
        history.push(`/quickbooks/${userID}/unisonglobus/spreadsheet`);
      }
      setLoading(false);
      setProgressPercentage(100);
      // console.log("Upload successful:", response.data);
    } catch (error) {
      setProgressPercentage(100);
      setLoading(false);
      console.log(error?.message, "Execute quincy error");
    }
  }

  async function initiateSyncData() {
    setSyncLoading(true);
    try {
      let response = await axios.post(
        "https://finacebackend.marketsverse.com/api/quickbooks/syncdata",
        {
          userId: userID,
        }
      );

      if (response?.data?.status) {
        toast.success("Synced successfully!", {
          duration: 4000,
          position: "top-right",
        });
        setSynced(true);
      } else {
        toast.error("Unable to sync data!", {
          duration: 4000,
          position: "top-right",
        });
      }
      setSyncLoading(false);
      console.log("Sync loading successful:", response);
    } catch (error) {
      setSyncLoading(false);
      console.log(error?.message, "Initiate sync data error");
    }
  }

  async function regenerateToken() {
    try {
      let response = await axios.post(
        "https://finacebackend.marketsverse.com/api/quickbooks/user/regenerate/token",
        {
          userId: userID,
        }
      );
    } catch (error) {
      console.log(error, "Regenerate token server error");
    }
  }

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Retrieve existing images from localStorage (at the start of each loop)
    let existingImages =
      JSON.parse(localStorage.getItem("paymentAdviseImages")) || [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const newImage = {
          name: file.name,
          url: reader.result, // Base64 string
        };

        // Update existing images dynamically after each file is read
        existingImages = [...existingImages, newImage];

        // Save the updated array back to localStorage
        localStorage.setItem(
          "paymentAdviseImages",
          JSON.stringify(existingImages)
        );
      };
    });
  };

  //renderings

  useEffect(() => {
    const passedData = location.state;

    if (passedData?.currentstep) {
      setCurrentStep(passedData?.currentstep);
      setUserID(passedData?.userid);
    }

    //clear images on first time
    localStorage.removeItem("paymentAdviseImages");
  }, []);

  return (
    <div
      className={classNames.authLogin}
      style={{ pointerEvents: syncLoading ? "none" : "" }}
    >
      <div className={classNames.loginContainer}>
        <div className={classNames.logoContainer}>
          <img src={Images.mainLogo} alt="accounts" />
        </div>
        {currentStep === "executeQuincy" ? (
          <>
            <div className={classNames.inputContainer}>
              <div className={classNames.eachField}>
                <div className={classNames.title}>
                  Upload your new spreadsheet
                </div>
                <CustomFileUpload
                  placeholderImage={cloudUploadIcon}
                  type="single"
                  name="spreadsheet"
                  stateValue={allFiles}
                  setState={setAllFiles}
                />
              </div>
              <div className={classNames.eachField}>
                <div className={classNames.title}>Upload payment advises</div>
                <CustomFileUpload
                  placeholderImage={uploadPlusIcon}
                  type="multiple"
                  name="paymentAdvises"
                  stateValue={allFiles}
                  setState={setAllFiles}
                  func={handleImageChange}
                />
              </div>
            </div>
            <div className={classNames.mainContent}>
              <button
                className={classNames.transparentBtn}
                onClick={() => {
                  executeQuincyFileUpload(userID, allFiles);
                }}
              >
                Submit
              </button>
            </div>
          </>
        ) : currentStep === "step 2" ? (
          <>
            <div className={classNames.inputContainer}>
              <div className={classNames.title}>What would you like to do?</div>
              <button
                className={classNames.transparentBtn}
                onClick={initiateSyncData}
              >
                <span>Initiate sync</span>
                {syncLoading ? <SpinnerLoading /> : synced ? <TiTick /> : ""}
              </button>
              <button
                className={classNames.transparentBtn}
                onClick={() => setCurrentStep("executeQuincy")}
              >
                Execute Quincy
              </button>
              <button className={`${classNames.transparentBtn} disabledBtn`}>
                See previous Qunicy’s
              </button>
              <button
                className={classNames.transparentBtn}
                onClick={() => history.push(`/${userID}/unisonglobus/payees`)}
              >
                See existing synced data
              </button>
            </div>
            <div className={classNames.mainContent}>
              <button
                className={classNames.transparentBtn}
                onClick={() => setCurrentStep("step 1")}
              >
                Go Back
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={classNames.inputContainer}>
              {/* <div className={classNames.eachField}>
                <div className={classNames.title}>What is the auth token?</div>
                <input
                  className={classNames.inputField}
                  type="text"
                  placeholder="Enter auth"
                />
              </div> */}
              <div className={classNames.eachField}>
                <div className={classNames.title}>What is the User ID?</div>
                <input
                  className={classNames.inputField}
                  type="text"
                  placeholder="Enter User ID"
                  value={userID}
                  onChange={(event) => setUserID(event?.target?.value)}
                />
              </div>
            </div>
            <div className={classNames.mainContent}>
              <button
                className={`${classNames.transparentBtn} ${
                  userID?.length > 0 ? "" : "disabledBtn"
                }`}
                onClick={() => {
                  regenerateToken();
                  setCurrentStep("step 2");
                }}
              >
                Proceed
              </button>
              <button
                className={classNames.transparentBtn}
                onClick={() => history.push("/")}
              >
                Go Back
              </button>
            </div>
          </>
        )}
      </div>
      {loading && (
        <div className={classNames.loadingContainer}>
          <div>
            <img src={Images.mainLogo} alt="accounts" />
          </div>
        </div>
      )}
      {loading && (
        <div className={classNames.progressContainer}>
          <div
            className={classNames.successBar}
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      )}
    </div>
  );
}

export default WithAuth;
