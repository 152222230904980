import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import "./App.scss";
import Accounts from "./Components/Accounts/Accounts";
import LandingPage from "./Components/LandingPage/LandingPage";
import LoginPage from "./Components/LoginPage/LoginPage.jsx";
import ContextProvider from "./Components/Context/Context";
import PreLogin from "./Components/PreLogin";
import { Toaster } from "react-hot-toast";

function App() {
  const history = useHistory();
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={PreLogin} />
        <Route path="/login" component={LoginPage} />
        <Route path="/admin/login" component={LoginPage} />
        <Route path="/admin/bankers" component={LoginPage} />
        <Route path="/auth/login" component={LoginPage} />
        <Route path="/2fa" component={LoginPage} />
        <Route path="/reset" component={LoginPage} />
        {/* <Route exact path="/" component={LandingPage} /> */}
        <Route
          path="/"
          render={() => (
            <ContextProvider>
              <Accounts />
            </ContextProvider>
          )}
        />
        <Redirect to="/about" />
      </Switch>
      <Toaster />
    </div>
  );
}

export default App;
