import React from "react";
import { useHistory } from "react-router";
import classNames from "./prelogin.module.scss";
import Images from "../../assets/0-exporter";

function PreLogin() {
  const history = useHistory();

  // React.useEffect(() => {
  //   localStorage.clear();
  // }, []);

  return (
    <div className={classNames.loginPageMain}>
      <div className={classNames.loginContainer}>
        <div className={classNames.logoContainer}>
          <img src={Images.mainLogo} alt="accounts" />
        </div>
        <div className={classNames.mainContent}>
          <div className={classNames.title}>
            Which environment would you like to access?
          </div>
          <button
            className={classNames.transparentBtn}
            onClick={() => history.push("/login")}
          >
            Standard Login
          </button>
          <button
            className={classNames.transparentBtn}
            onClick={() => history.push("/auth/login")}
          >
            Demo with auth
          </button>
          {/* <button className={classNames.transparentBtn}>
            Demo without auth
          </button> */}
          <button
            className={classNames.transparentBtn}
            onClick={() => history.push("/admin/login")}
          >
            Admin
          </button>
        </div>
      </div>
    </div>
  );
}

export default PreLogin;
