import React, { useEffect, useState } from "react";
import reactDom from "react-dom";
import { useHistory, useParams } from "react-router";
import Images from "../../../assets/0-exporter";
import { Context } from "../../Context/Context";
import DownloadPortal from "../../Portals/DownloadPortal/DownloadPortal";
import MainMenu from "../../Portals/MainMenu/MainMenu";
import AccountSelection from "../AccountSelection/AccountSelection";
import BondEarningsTable from "./BondEarnings/BondEarningsTable";
import "./dashboard.style.scss";
import LiquidAsset from "./LiquidAsset/LiquidAsset";
import MoneyMarketTable from "./MoneyMarket/MoneyMarketTable";
import SplitTable from "./SplitTable/SplitTable";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import QuickBooksTable from "./QuickBooksTable/QuickBooksTable";
import { CustomInput } from "../../Custom";
import axios from "axios";
import toast from "react-hot-toast";

function QuickBooksDashboard() {
  const downloadRef = React.useRef();
  const history = useHistory();
  const { userid, email, app, type, subType } = useParams();
  const context = React.useContext(Context);
  const {
    tabsConfig,
    selectedTab,
    refreshTable,
    openDownloadTab,
    userDetails,
    updateState,
    currencyImageList,
    valueFormatter,
    registeredApps,
    nameImageList,
    miniTabSelected,
    refresh,
  } = context;
  const [addType, setAddType] = React.useState("");
  const [openMenu, setOpenMenu] = React.useState(false);
  const [splitNum, setSplitNum] = React.useState(0);
  // State to store uploaded images
  const [images, setImages] = useState([]);
  const [showAdvises, setShowAdvises] = useState(false);
  const [createPayee, setCreatePayee] = useState(false);

  const handlePDFDownload = () => {
    savePDF(downloadRef.current, {
      paperSize: "A1",
      fileName: `${selectedTab.app.app_name}-${type}-${selectedTab.subType} Ledger.pdf`,
    });
  };

  React.useEffect(() => {}, [miniTabSelected]);
  const handleMenuClick = (obj) => {
    switch (obj.id) {
      case "store":
        break;
      case "refresh":
        updateState("refreshTable", true);
        let a = setTimeout(() => {
          updateState("refreshTable", null);
          clearTimeout(a);
        }, 400);
        break;
      case "theatre":
        break;
      case "chats":
        break;
      case "accountant":
        break;
      case "logout":
        localStorage.clear();
        history.push("/login");
        break;

      default:
        break;
    }
  };
  const renderAccountSelection = () => {
    switch (addType) {
      case "app":
        return (
          <AccountSelection
            type="app"
            closeIt={() => setAddType("")}
            app={true}
          />
        );

        break;
      case "type":
        return (
          <AccountSelection
            closeIt={() => setAddType("")}
            selectedApp={selectedTab.app}
            type={true}
            keyIds={selectedTab.keyId}
            type="type"
          />
        );

        break;
      case "split":
        return (
          <AccountSelection
            type="split"
            selectedApp={selectedTab.app}
            closeIt={() => setAddType("")}
            splitNum={splitNum}
          />
        );

      default:
        return "";
    }
  };

  React.useEffect(() => {
    if (!selectedTab) return;
    let tempObj = selectedTab.subTabs.find((x) => {
      return x.id === type && x.type === subType;
    });
    updateState("miniTabSelected", { ...tempObj });
  }, [type]);

  //renderings

  useEffect(() => {
    let paymentAdviceImages = localStorage.getItem("paymentAdviseImages");
    if (paymentAdviceImages) {
      setImages(JSON.parse(localStorage.getItem("paymentAdviseImages")));
    }
  }, []);

  return (
    <div className="dashboard-main">
      {openMenu ? (
        <MainMenu
          handleClick={handleMenuClick}
          closeIt={() => setOpenMenu(false)}
          userDetails={userDetails}
        />
      ) : (
        ""
      )}
      {openDownloadTab ? (
        <DownloadPortal
          subType={subType}
          handlePDFDownload={handlePDFDownload}
          email={email}
          registeredApps={registeredApps}
          nameImageList={nameImageList}
          valueFormatter={valueFormatter}
          type={type}
          closeIt={() => updateState("openDownloadTab", false)}
          selectedTab={selectedTab}
          userDetails={userDetails}
        />
      ) : (
        ""
      )}
      <div className={addType ? "add-app-modal" : "d-none"}>
        {renderAccountSelection()}
      </div>
      <div className="dashboard-header">
        <div onClick={() => setOpenMenu(true)} className="d-h-title">
          <img src={Images.mainLogo} alt="accounts" />
        </div>
        <div className="d-h-tabs">
          <div className="d-h-tabs-wrapper">
            <div
              //  onClick={() => handleCard(obj)}
              className="selected-tab"
            >
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  // handleRemoveApp(obj);
                }}
                className="cancel"
                src={Images.cancel}
                alt="cancel"
              />
              <h5>
                {/* <img className="main-image" src={brokerAppLogo} />{" "} */}
                <span>Unison Globus</span>
              </h5>
            </div>
          </div>
        </div>
        <div
          className="returnBackBtn"
          onClick={() =>
            history.push("/auth/login", {
              userid: userid,
              currentstep: "step 2",
            })
          }
        >
          Back to Homepage
        </div>
        <div onClick={() => setAddType("app")} className="d-h-add">
          <img src={Images.plus} />
        </div>
      </div>
      <div className="dashboard-sub-header">
        <div className="dashboard-sub-header-wrapper">
          <div
            onClick={() => {
              // console.log("just now", obj);
              // updateState("miniTabSelected", obj);
              // history.push(
              //   `/${localStorage.getItem("userEmail")}/${app}/${obj.id}/${
              //     obj.type
              //   }`
              // );
            }}
            className={"selected-sub-tab"}
          >
            <img
              onClick={(e) => {
                e.stopPropagation();
                // handleRemoveType(obj);
              }}
              className="cancel"
              src={Images.cancel}
              alt="cancel"
            />
            {/* <img className="main-image" src={obj.icon} />{" "}
                  <h6>
                    {obj.name} {"("}
                    {obj.type}
                    {")"}
                    {obj.subType ? (
                      <p
                        onClick={(e) => {
                          e.stopPropagation();
                          obj.id === type && obj.type === subType
                            ? removeSubType(num)
                            : console.log();
                        }}
                      >
                        <img src={obj.subType.coin.icon} />
                        {obj.subType.coin.name}
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveType(obj);
                          }}
                          className="cancel"
                          src={Images.cancel}
                          alt="cancel"
                        />
                      </p>
                    ) : (
                      <button
                        onClick={() => {
                          setAddType("split");
                          setSplitNum(num);
                        }}
                      >
                        <img src={Images.plus} />
                      </button>
                    )}
                  </h6> */}
            <h6>Payees</h6>
          </div>
        </div>
        <div className="dashboard-sub-header-options">
          {/* <button>
            <img src={Images.settings} />
          </button>
          <button onClick={() => setAddType("type")}>
            {" "}
            <img src={Images.plus} />
          </button> */}
          {showAdvises && (
            <PaymentAdviceUploader images={images} showAdvises={showAdvises} />
          )}
          <div
            className="paymentAdvises"
            onClick={() => setShowAdvises(!showAdvises)}
          >
            {showAdvises ? "Close Payment Advices" : "Show Payment Advices"}
          </div>
        </div>
      </div>

      <div className="dashboard-sub-body">
        <div
          className={`dashboard-main-table ${
            !miniTabSelected?.subType ? "" : "half-main-table"
          }`}
        >
          <QuickBooksTable
            downloadRef={downloadRef}
            split={!miniTabSelected?.subType ? false : true}
            createPayee={createPayee}
            setCreatePayee={setCreatePayee}
          />
        </div>
        <div
          className={`dashboard-split-table ${
            !miniTabSelected?.subType ? "" : "full-split-table"
          }`}
        >
          {!miniTabSelected?.subType ? (
            ""
          ) : (
            <SplitTable
              currencyImageList={currencyImageList}
              config={miniTabSelected?.subType}
            />
          )}
        </div>
      </div>
      {createPayee && (
        <div
          className="preview-container"
          style={{ background: "transparent" }}
        >
          <div
            className="preview-container"
            onClick={() => setCreatePayee(false)}
          ></div>
          <CreatePayee setCreatePayee={setCreatePayee} />
        </div>
      )}
    </div>
  );
}

export default QuickBooksDashboard;

function PaymentAdviceUploader({ images, showAdvises }) {
  const [previewImage, setPreviewImage] = useState(null);

  // Handle preview close
  const closePreview = () => {
    setPreviewImage(null);
  };

  console.log(images, "images");

  return (
    <div className="payment-advice-container">
      {/* Grid of uploaded advises */}
      {showAdvises && (
        <div className="advice-grid">
          {images.map((img, index) => (
            <div
              className="eachAdviceImage"
              onClick={() => setPreviewImage(img.url)}
            >
              <img
                key={index}
                src={img.url}
                alt={`Uploaded ${index + 1}`}
                className="advice-thumbnail"
              />
              <div className="title">{img?.name}</div>
            </div>
          ))}
        </div>
      )}

      {/* Preview Image */}
      {previewImage && (
        <div className="preview-container">
          <div className="preview-overlay" onClick={closePreview}></div>
          <div className="preview-content">
            <img src={previewImage} alt="Preview" className="preview-image" />
            <button className="close-preview-btn" onClick={closePreview}>
              Close Preview
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const CreatePayee = ({ setCreatePayee }) => {
  const { userid } = useParams();
  const [payeeType, setPayeeType] = useState("");
  const [payeeDetails, setPayeeDetails] = useState({});

  // async function regenerateToken() {
  //   try {
  //     let response = await axios.post(
  //       "https://finacebackend.marketsverse.com/api/quickbooks/user/regenerate/token",
  //       {
  //         userId: userid,
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error, "Regenerate token server error");
  //   }
  // }

  async function createPayee(URI) {
    try {
      let response = await axios.post(
        `https://finacebackend.marketsverse.com/api/quickbooks${URI}`,
        {
          userId: userid,
          displayName: payeeDetails?.displayName,
        }
      );
      if (response?.data?.status) {
        toast.success("Created Payee Successfully!", {
          duration: 4000,
          position: "top-right",
        });
        setPayeeType("");
        setPayeeDetails({});
        setCreatePayee(false);
      } else {
        toast.error("Error while creating payee, please try again!", {
          duration: 4000,
          position: "top-right",
        });
      }
      console.log(response, "created payee successfully");
    } catch (error) {
      toast.error("Error while creating payee, please try again!", {
        duration: 4000,
        position: "top-right",
      });
      console.log(error?.message, "Create payee server error");
    }
  }

  return (
    <div className="createVendorForm">
      <div className="title">Create Payee</div>
      <div className="allInputs">
        <div className="eachItem">
          <div className="title">Select Payee Type</div>
          <div className="selectOne">
            <div
              className={`${payeeType === "Vendor" && "selectedType"}`}
              onClick={() => setPayeeType("Vendor")}
            >
              Vendor
            </div>
            <div
              className={`${payeeType === "Customer" && "selectedType"}`}
              onClick={() => setPayeeType("Customer")}
            >
              Customer
            </div>
          </div>
        </div>
        <div className="eachItem">
          <div className="title">Display Name</div>
          <div className="inputContainer">
            <CustomInput
              placeholder="Enter display name..."
              type="text"
              name="displayName"
              stateValue={payeeDetails}
              setState={setPayeeDetails}
            />
          </div>
        </div>
      </div>
      
      <div
        className={`submitBtn ${
          payeeType && payeeDetails?.displayName ? "" : "notAllowed"
        }`}
        onClick={() =>
          createPayee(
            payeeType === "Vendor" ? "/vendor/create" : "/customer/create"
          )
        }
      >
        Create Payee
      </div>
    </div>
  );
};
