import React from "react";
import { useHistory } from "react-router";
import { login } from "../../services/postAPIs";
import Bankers from "./Bankers/Bankers";
import "./login-page.style.scss";
import NormalLogin from "./NormalLogin/NormalLogin";
import ResetPassword from "./ResetPassword/ResetPassword";
import TwoFA from "./TwoFA/TwoFA";
import { useLocation } from "react-router-dom";
import WithAuth from "./WithAuth";
export default function LoginPage() {
  const history = useHistory();
  const location = useLocation();
  console.log(location, "location in login");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [type, setType] = React.useState("normal"); //normal
  const [rsStep, setRsStep] = React.useState(0);
  const [credential, setCredential] = React.useState({
    email: "",
    password: "",
  });
  const handleSubmit = async () => {
    setLoading(true);
    let res = await login(credential.email, credential.password);
    if (res.data.status) {
      localStorage.setItem("userEmail", credential.email);
      localStorage.setItem("deviceKey", res.data.device_key);
      localStorage.setItem("accessToken", res.data.accessToken);
      localStorage.setItem("idToken", res.data.idToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      localStorage.setItem("theme", "light");
      history.push("/select");
      setLoading(false);
    } else if (res.data.mfa) {
      history.push("/2fa");
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };
  const handleSubmitAdmin = async () => {
    setLoading(true);
    if (credential.email !== "shorupan@gmail.com") return;
    let res = await login(credential.email, credential.password);
    if (res.data.status) {
      localStorage.setItem("userEmail", credential.email);
      localStorage.setItem("deviceKey", res.data.device_key);
      localStorage.setItem("accessToken", res.data.accessToken);
      localStorage.setItem("idToken", res.data.idToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      setLoading(false);
      history.push("/admin/bankers");
    } else {
      setError(true);
      setLoading(false);
    }
  };
  const handleBack = () => {
    switch (location.pathname) {
      case "/admin/login":
      case "/2fa":
      case "/auth/login":
        history.push("/login");
        break;
      case "/reset":
        if (!rsStep) {
          history.push("/login");
        } else {
          setRsStep(rsStep - 1);
        }
        break;
      case "/admin/banker":
        history.push("/admin/login");
        setType("admin");
        setCredential({
          email: "",
          password: "",
        });
        break;

      default:
        history.push("/");
        break;
    }
  };
  const handleTwoFA = (obj) => {};
  const selectComponent = () => {
    switch (location.pathname) {
      case "/login":
        return (
          <NormalLogin
            setType={setType}
            loading={loading}
            setCredential={setCredential}
            credential={credential}
            error={error}
            handleSubmit={handleSubmit}
          />
        );
      case "/2fa":
        return <TwoFA handleTwoFA={handleTwoFA} credential={credential} />;
      case "/admin/bankers":
        return <Bankers />;
      case "/admin/login":
        return (
          <NormalLogin
            loading={loading}
            setCredential={setCredential}
            credential={credential}
            error={error}
            handleSubmit={handleSubmitAdmin}
            admin={true}
          />
        );
      case "/auth/login":
        return <WithAuth />;
      case "/reset":
        return (
          <ResetPassword
            setCredential={setCredential}
            setType={setType}
            step={rsStep}
            setStep={setRsStep}
          />
        );

      default:
        break;
    }
  };

  // React.useEffect(() => {
  //   localStorage.clear();
  // }, []);

  return (
    <div className="login-page-main">
      <button onClick={() => handleBack()} className="login-back">
        Back
      </button>
      {/* <button
        onClick={() => history.push("/admin/login")}
        className={`login-admin ${
          ["/admin/login", "/admin/bankers"].includes(location.pathname)
            ? "d-none"
            : ""
        }`}
      >
        Admin
      </button> */}
      {selectComponent()}
    </div>
  );
}
