import React, { useState } from "react";
import nextId from "react-id-generator";
import "./quickbookstable.style.scss";
import { useHistory, useParams } from "react-router";
import { Context } from "../../../Context/Context";
import {
  fetchSyncTransactionPayees,
  liquidAssetList,
} from "../../../../services/postAPIs";
import LoadingAnimation from "../../../../lotties/LoadingAnimation";
import ChangeCurrency from "../../../Portals/ChangeCurrency/ChangeCurrency";
import Images from "../../../../assets/0-exporter";
import axios from "axios";
import { EachCustomDropdown } from "../../../Custom";
import toast from "react-hot-toast";
import accountList from "../../../../assets/const/account_list.json"; // Adjust the path as needed
import { downloadCSVWithJSON } from "../../../../assets/functions";
import { LiaEdit } from "react-icons/lia";
import { IoClose } from "react-icons/io5";

function QuickBooksTable({
  split,
  handlePDFDownload,
  downloadRef,
  createPayee,
  setCreatePayee,
}) {
  const { userid, app, type } = useParams();
  const liquidListRef = React.useRef();
  const [shadow, setShadow] = React.useState(false);
  const context = React.useContext(Context);
  const {
    selectedTab,
    valueFormatter,
    currencyImageList,
    userDetails,
    dateFormatter,
    nameImageList,
    refreshTable,
    updateState,
    miniTabSelected,
  } = context;

  const [transactionList, setTransactionList] = React.useState([]);
  const [failedTransactionsList, setFailedTransactionsList] = React.useState(
    []
  );
  const [transactionListResponse, setTransactionListResponse] = React.useState(
    []
  );
  const [invoicesList, setInvoicesList] = React.useState([]);
  const [billsList, setBillsList] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [changeCurrency, setChangeCurrency] = React.useState("");
  const [selectedTransaction, setSelectedTransaction] = React.useState({
    num: 0,
    data: null,
  });
  const [actionBtnOpen, setActionBtnOpen] = useState(false);

  const setUpTransactionList = async () => {
    let res = localStorage.getItem("processTransactions")
      ? JSON.parse(localStorage.getItem("processTransactions"))
      : [];
    if (res?.data?.status && res?.data?.data?.transactions?.length > 0) {
      let temp = res.data?.data?.transactions.map((obj) => {
        return {
          ...obj,
          // displayCurrency: type,
          // displayValue: obj.amount,
        };
      });
      let failedTransactions = res.data?.data?.transactions
        .filter((item) => !item.completed) // Include only items where completed is false
        .map(
          ({
            type,
            date,
            transaction_description,
            amount,
            vector_found,
            completed,
          }) => ({
            date,
            transaction_description,
            type,
            amount,
            comment: !vector_found
              ? "Payee not found!"
              : completed
              ? "Transaction Completed"
              : "Unable to complete transaction",
          })
        );

      console.log(temp, "all transactions");
      // localStorage.setItem(miniTabSelected.keyId, JSON.stringify(temp));
      setTransactionList([...temp]);
      setFailedTransactionsList(failedTransactions);
      setBillsList(res.data?.data?.bills);
      setInvoicesList(res.data?.data?.invoices);
      setLoading(false);
    } else {
      setTransactionList([]);
      setLoading(false);
    }
  };

  const handleCoinClick = (obj, transaction) => {
    transactionList[transaction.num] = {
      ...transactionList[transaction.num],
      displayCurrency: obj.coin,
      displayValue: obj.value * transaction.data.displayValue,
    };
    localStorage.setItem(
      miniTabSelected.keyId,
      JSON.stringify([...transactionList])
    );
    setTransactionList([...transactionList]);
    setChangeCurrency("");
  };
  const handleScroll = () => {
    if (liquidListRef.current.scrollTop > 70) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };
  React.useEffect(() => {
    setLoading(true);
    if (userid) {
      setUpTransactionList();
    }
  }, [userid]);

  React.useEffect(() => {
    if (refreshTable === null) return;
    setLoading(true);
    setUpTransactionList();
  }, [refreshTable]);

  /* API declarations */

  async function updateTransactionsQuickBooks(userID, updatedTransactions) {
    setLocalLoading(true);

    let obj = { userId: userID, transactions: updatedTransactions };

    try {
      // Make the POST request
      let response = await axios.post(
        "https://finacebackend.marketsverse.com/api/quickbooks/update-records-qbo",
        obj
      );

      if (response?.data?.status) {
        updateTransactionStatus(response?.data?.data);
        toast.success("Successfully updated on quickbooks!", {
          duration: 4000,
          position: "top-right",
        });
      } else {
        toast.error("Unable to update transactions on quickbooks", {
          duration: 4000,
          position: "top-right",
        });
      }
      setLocalLoading(false);
      console.log("Upload quickbooks transactions successfully", response);
    } catch (error) {
      setLocalLoading(false);
      console.log(error?.message, "Update quickbooks transaction error");
    }
  }

  // all helper functions

  function updateTransactionStatus(apiResponse) {
    const updatedTransactionList = transactionList.map((transaction, index) => {
      // Update transaction based on matching id
      const response = apiResponse[index];

      // Add the status field based on the API response message
      const status = response?.message?.includes("failed")
        ? "Failure"
        : "Success";

      return {
        ...transaction,
        responseStatus: status, // Add status field to the transaction
      };
    });
    setTransactionListResponse(apiResponse);
    setTransactionList(updatedTransactionList);
  }

  // Function to handle text/number input changes

  const handleInputChangeValue = (e, index, path) => {
    const value = e;
    const keys = path.split(".");
    const updatedList = [...transactionList];

    let obj = updatedList[index];
    for (let i = 0; i < keys.length - 1; i++) {
      obj = obj[keys[i]];
    }

    obj[keys[keys.length - 1]] = value;
    setTransactionList(updatedList);
  };

  function handleMultipleIdChange(documentID, receiptID) {
    handleInputChangeValue(...documentID);
    handleInputChangeValue(...receiptID);
  }

  const handleInputChangeReceiptType = (e, index, path) => {
    const value = e;
    const keys = path.split(".");
    const updatedList = [...transactionList];

    let obj = updatedList[index];
    for (let i = 0; i < keys.length - 1; i++) {
      obj = obj[keys[i]];
    }

    if (value === "PAYMENT" || value === "BILL_PAYMENT") {
      obj[keys[keys.length - 1]] = value;
    } else {
      obj["document_ids"] = [];
      obj["receipt_ids"] = [];
      obj[keys[keys.length - 1]] = value;
    }

    // console.log(obj, "type change", updatedList);

    setTransactionList(updatedList);
  };

  return (
    <div
      ref={downloadRef}
      className={`liquid-asset-main ${split ? "split-affected" : ""}`}
    >
      {!changeCurrency ? (
        ""
      ) : (
        <ChangeCurrency
          critical="amount"
          handleCoinClick={handleCoinClick}
          currencyImageList={currencyImageList}
          valueFormatter={valueFormatter}
          closeIt={() => {
            setChangeCurrency("");
            setSelectedTransaction({ num: 0, data: null });
          }}
          transaction={selectedTransaction}
          currency={changeCurrency}
        />
      )}
      <div
        className={`liquid-asset-header ${shadow ? "show-shadow" : ""} ${
          transactionListResponse?.length > 0 ? "withTransactionResponse" : ""
        } `}
      >
        {headerList.slice(0, split ? 4 : 10).map((obj) => (
          <h6 key={obj.keyId}>{obj.name}</h6>
        ))}
        {transactionListResponse?.length > 0 && (
          <h6 key="transactionStatus">Transaction Status</h6>
        )}
      </div>
      <div
        onScroll={handleScroll}
        ref={liquidListRef}
        className={`liquid-asset-body ${
          transactionListResponse?.length > 0 ? "withTransactionResponse" : ""
        }`}
      >
        {loading || !currencyImageList ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => (
            <div className="liquid-asset-loading">
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
              <h6>
                <button />
              </h6>
            </div>
          ))
        ) : !transactionList.length ? (
          <div className="empty-list">
            <h4>No Transaction Found for {type}.</h4>
          </div>
        ) : (
          transactionList.map((obj, num) => (
            <EachTransactionRow
              obj={obj}
              num={num}
              transactionListResponse={transactionListResponse}
              handleInputChangeReceiptType={handleInputChangeReceiptType}
              handleMultipleIdChange={handleMultipleIdChange}
              billsList={billsList}
              invoicesList={invoicesList}
              actionBtnOpen={actionBtnOpen}
              setActionBtnOpen={setActionBtnOpen}
              createPayee={createPayee}
              setCreatePayee={setCreatePayee}
            />
          ))
        )}
      </div>
      <div className="liquid-asset-footer">
        <h6>
          {!transactionList || loading ? 0 : transactionList.length}{" "}
          Transactions
        </h6>
        <div className="submitButtonContainer">
          <div
            className="submitBtn"
            onClick={() => {
              // console.log(failedTransactionsList, "failedTransactionsList");
              downloadCSVWithJSON(failedTransactionsList);
            }}
          >
            Download Failed Transactions
          </div>
          <div
            className="submitBtn"
            onClick={() =>
              updateTransactionsQuickBooks(userid, transactionList)
            }
          >
            Submit To Quickbooks
          </div>
        </div>
      </div>
      {localLoading && (
        <div className="loadingContainer">
          <div>
            <img src={Images.mainLogo} alt="accounts" />
          </div>
        </div>
      )}
    </div>
  );
}

export default QuickBooksTable;

const EachTransactionRow = ({
  obj,
  num,
  transactionListResponse,
  handleInputChangeReceiptType,
  handleMultipleIdChange,
  billsList,
  invoicesList,
  actionBtnOpen,
  setActionBtnOpen,
  createPayee,
  setCreatePayee,
}) => {
  return (
    <div
      className={`single-transaction ${
        transactionListResponse?.length > 0 &&
        "single-transaction-with-response-status"
      }`}
      key={obj.id}
    >
      <h6>{obj?.type ? obj?.type : "-"}</h6>
      <h6>{obj?.date ? obj?.date : "-"}</h6>
      <h6>{obj.transaction_description ? obj.transaction_description : "-"}</h6>
      {/* <h6>{obj.category_id ? obj.category_id : "-"}</h6> */}
      {/* <h6>{obj.category_name ? obj.category_name : "-"}</h6> */}
      {obj?.receipt?.type === "PURCHASE" ? (
        <div className="multipleDataCells">
          <div>
            <div className={`dropdownAdjust`}>
              <EachCustomDropdown
                dropdown={accountList}
                name=""
                stateValueDirect={obj.category_name ? obj.category_name : "-"}
                placeholder="Select Category Name"
                setStateFunc={handleMultipleIdChange}
                setStateValueObj={{
                  documentID: [num, "category_name"],
                  receiptID: [num, "category_id"],
                }}
                typee="objVal"
                mapName="category_name"
                objName="category_id"
              />
            </div>
          </div>
        </div>
      ) : (
        <h6>-</h6>
      )}

      {/* <div className="singleDataCells">
                <input
                  type="text"
                  value={obj.transaction_description || ""}
                  onChange={(e) =>
                    handleInputChange(e, num, "transaction_description")
                  }
                />
              </div> */}

      {/* Payee Details */}
      <div className="multipleDataCells">
        <div>
          <span className="type">ID:</span>
          <span>{obj.payee.id}</span>
        </div>
        <div>
          <span className="type">Name:</span>
          <span>{obj.payee.name}</span>
        </div>
        <div>
          <span className="type">Type:</span>
          <span>{obj.payee.type}</span>
        </div>
      </div>

      {/* Receipt Details (Dynamic Fields) */}
      {/* first condition is temporary */}
      {obj?.receipt?.type === "BILL_PAYMENT" &&
      obj.receipt?.document_ids?.length < 1 &&
      obj.receipt?.receipt_ids?.length < 1 ? (
        <div className="multipleDataCells" style={{ pointerEvents: "none" }}>
          <div>
            <span className="type">Type:</span>
            <div className={`dropdownAdjust`}>
              <EachCustomDropdown
                dropdown={[
                  "BILL_PAYMENT",
                  "PAYMENT",
                  "PURCHASE",
                  "VENDOR_CREDIT",
                  "SALES_RECEIPT",
                  "REFUND_RECEIPT",
                ]}
                name=""
                stateValueDirect={"BILL_ADVANCE"}
                setStateFunc={handleInputChangeReceiptType}
                setStateValueArray={[num, "receipt.type"]}
                typee="single"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="multipleDataCells">
          {/* <div>
                  <span className="type">Receipt IDs:</span>
                  {(obj.receipt?.receipt_ids?.length > 0
                    ? obj.receipt.receipt_ids
                    : [""]
                  ).map((id, index) => (
                    <input
                      key={`receipt-id-${index}`}
                      type="text"
                      placeholder="Enter receipt ID"
                      value={id || ""}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          num,
                          `receipt.receipt_ids.${index}`
                        )
                      }
                    />
                  ))}
                </div> */}
          {(obj?.receipt?.type === "BILL_PAYMENT" ||
            obj?.receipt?.type == "PAYMENT") && (
            <div>
              <span className="type">Document IDs:</span>
              {(obj.receipt?.document_ids?.length > 0
                ? obj.receipt.document_ids
                : [""]
              ).map((docId, index) => (
                // <input
                //   key={`document-id-${index}`}
                //   type="text"
                //   placeholder="Enter document ID"
                //   value={docId || ""}
                //   onChange={(e) =>
                //     handleInputChange(
                //       e,
                //       num,
                //       `receipt.document_ids.${index}`
                //     )
                //   }
                // />
                <div className={`dropdownAdjust`}>
                  <EachCustomDropdown
                    dropdown={
                      obj?.receipt?.type === "PAYMENT"
                        ? invoicesList
                        : billsList
                    }
                    name=""
                    stateValueDirect={docId ? docId : "-"}
                    placeholder="Select Document ID"
                    setStateFunc={handleMultipleIdChange}
                    setStateValueObj={{
                      documentID: [num, `receipt.document_ids.${index}`],
                      receiptID: [num, `receipt.receipt_ids.${index}`],
                    }}
                    typee="objVal"
                    mapName="id_document"
                    objName="id"
                  />
                </div>
              ))}
            </div>
          )}
          <div>
            <span className="type">Type:</span>
            <div className={`dropdownAdjust`}>
              <EachCustomDropdown
                dropdown={[
                  "BILL_PAYMENT",
                  "PAYMENT",
                  "PURCHASE",
                  "VENDOR_CREDIT",
                  "SALES_RECEIPT",
                  "REFUND_RECEIPT",
                ]}
                name=""
                stateValueDirect={obj?.receipt?.type}
                setStateFunc={handleInputChangeReceiptType}
                setStateValueArray={[num, "receipt.type"]}
                typee="single"
              />
            </div>
          </div>
        </div>
      )}

      <h6>{obj?.amount ? obj?.amount : "-"}</h6>
      {/* <h6>{obj?.completed ? "True" : "False"}</h6>
      <h6>{obj?.vector_found ? "True" : "False"}</h6> */}
      <h6>
        {!obj?.vector_found
          ? "Payee not found!"
          : obj?.completed
          ? "Transaction Completed"
          : "Unable to complete transaction"}
      </h6>
      <div className="actionsBtnContainer">
        <div className={`actionsBtn ${obj?.vector_found ? "notAllowed" : ""}`}>
          {actionBtnOpen === num ? (
            <IoClose onClick={() => setActionBtnOpen(false)} />
          ) : (
            <LiaEdit onClick={() => setActionBtnOpen(num)} />
          )}
          {actionBtnOpen === num && (
            <div className="allItems">
              <div className="eachItem" onClick={() => setCreatePayee(obj)}>
                Create Payee
              </div>
            </div>
          )}
        </div>
      </div>
      {transactionListResponse?.length > 0 && (
        <h6>{obj?.responseStatus ? obj?.responseStatus : "-"}</h6>
      )}
    </div>
  );
};

const headerList = [
  { keyId: nextId(), name: "Type", id: "type" },
  { keyId: nextId(), name: "Date", id: "date" },
  { keyId: nextId(), name: "Description", id: "description" },
  // { keyId: nextId(), name: "Category ID", id: "category_id" },
  { keyId: nextId(), name: "Category Name", id: "category_name" },
  { keyId: nextId(), name: "Payee", id: "payee" },
  { keyId: nextId(), name: "Receipt", id: "receipt" },
  { keyId: nextId(), name: "Amount", id: "amount" },
  // { keyId: nextId(), name: "Completed", id: "completed" },
  // { keyId: nextId(), name: "Vector Found", id: "vectorFound" },
  { keyId: nextId(), name: "Comments", id: "comments" },
  { keyId: nextId(), name: "Action", id: "action" },
];
