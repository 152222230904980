import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import LoadingAnimation from "../../lotties/LoadingAnimation";
import { authenticate } from "../../services/postAPIs";
import AccountSelection from "./AccountSelection/AccountSelection";
import Dashboard from "./Dashboard/Dashboard";
import "./accounts.style.scss";
import ManualLoading from "../../loadings/ManualLoading";
import { Context } from "../Context/Context";
import FinaidStudioDashboard from "./Dashboard/FinaidStudioDashboard";
import QuickBooksDashboard from "./Dashboard/QuickbooksDashboard";
export default function Accounts() {
  const context = React.useContext(Context);
  const { updateState } = context;
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const checkAuth = async () => {
    let res = await authenticate();
    if (res.data.status) {
      setLoading(false);
    } else {
      // history.push("/login");
      // localStorage.clear();
      setLoading(false);
    }
  };
  React.useEffect(() => {
    checkAuth();
  }, []);
  React.useEffect(() => {
    let current = localStorage.getItem("theme");
    updateState("currentTheme", current);
    if (current === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
    }
  }, []);
  return loading ? (
    <ManualLoading />
  ) : (
    <Switch>
      <Route
        path="/quickbooks/:userid/:app/:type"
        component={QuickBooksDashboard}
      />
      <Route path="/:email/:app/:type/:subType" component={Dashboard} />
      <Route path="/:userid/:app/:type" component={FinaidStudioDashboard} />
      <Route path="/select" component={AccountSelection} />
      {/* <Redirect to="/select" /> */}
    </Switch>
  );
}
